<template>
    <div class="confirmation-container">
        <div class="message-box">
            <h4 class="text-primary font-poppins-semibold ml-auto">Thank You for Your Submission!</h4>
                <p class="p-centered"><b>Your interview has been successfully submitted.</b></p>
            <div class="ml-auto">
                <btn text="Close" @click="closeWindow" class="px-4"></btn>
            </div>
                </div>
        </div>
</template>

<script>
export default {
    methods: {
        closeWindow () {
            console.log('closing window.....');
            window.close();
        }
    }
};
</script>

<style scoped>

</style>
